<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-19.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 30.png">
    </div>
    <!-- <div class="banner">
        <div class="banner-wrapper">
            <h1 class="fs-100">ติดต่อเรา</h1>
        </div>
    </div> -->
    <div class="text-about mt-3">
        <div class="text-wrapper rounded-top-3 p-3 white">
            <div class="contact-sec1">
                <div style="height: 10%;">
                    <h1 class="text-light-green-main fs-30 mb-2">ส่งข้อความถึงเรา</h1>
                    <h1 class="font-weight-light">เรารอที่จะตอบคำถามให้กับคุณทุกเมื่อ</h1>
                </div>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div style="height: 80%; display: flex; justify-content: center; align-items: center; ">
                        <div style="margin: 0 auto; height: fit-content;">
                            <div class="mo-name">
                                <input class="form-control text-contact" type="text" id="name" formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                    style="display: inline-block; width: 100%;" placeholder="ชื่อของคุณ*">
                                <div class="invalid-feedback" *ngIf="submitted && f.name.errors">
                                    <p *ngIf="f.name.errors.required" class="font-12">กรุณากรอกชื่อของคุณ</p>
                                </div>
                            </div>
                            <div class="mo-con">
                                <input class="form-control text-contact" type="tel" id="contactnum"
                                    formControlName="contactnumber"
                                    [ngClass]="{ 'is-invalid': submitted && f.contactnumber.errors }"
                                    style="display: inline-block; width: 100%;" placeholder="เบอร์ติดต่อกลับ*">
                                <div class="invalid-feedback" *ngIf="submitted && f.contactnumber.errors">
                                    <p *ngIf="f.contactnumber.errors.required" class="font-12">กรุณากรอกเบอร์ติดต่อกลับ
                                    </p>
                                </div>
                            </div>
                            <div>
                                <input class="form-control text-contact" type="email" id="email" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                    style="display: inline-block; width: 100%;" placeholder="อีเมลล์ของคุณ*">
                                <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                                    <p *ngIf="f.email.errors.required" class="font-12">กรุณากรอกอีเมลล์ของคุณ</p>
                                </div>
                            </div>
                            <div>
                                <textarea class="form-control text-contact" formControlName="text"
                                    [ngClass]="{ 'is-invalid': submitted && f.text.errors }"
                                    style="width: 100%; height: 170px;"
                                    placeholder="ใส่ข้อความของคุณที่นี่*"></textarea>
                                <div class="invalid-feedback" *ngIf="submitted && f.text.errors">
                                    <p *ngIf="f.text.errors.required" class="font-12">กรุณากรอกข้อความที่ต้องการส่ง</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <button mdbBtn class="bg-soft-green rounded-pill text-white" mdbWavesEffect
                            [disabled]="loading ? 'disabled': null">
                            <div *ngIf="loading">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                กำลังส่งข้อมูล
                            </div>
                            <div *ngIf="!loading">ส่งข้อความถึงเรา</div>
                        </button>
                    </div>
                </form>
            </div>
            <div class="contact-sec2">
                <iframe
                    class='mb-3' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.4891055168932!2d100.57522461431164!3d13.749352301055797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f94ff5d3c9b%3A0x2d5cc1514cdb5f97!2sFeels+Telecom+Corporation+..!5e0!3m2!1sth!2sth!4v1565682524887!5m2!1sth!2sth"
                    width="100%" height="300" frameborder="0" style="border:0" allowfullscreen></iframe>
                <p class="m-0 mr-3 d-inline font-weight-cus-bold">Feels Call Center</p><a href="tel:021147069" class="address cus-link d-inline font-weight-cus-bold">+66(0)2 114 7069</a><br>
                <p class="mt-3">21/111-112 Soi Soonvijai, Rama 9 rd.<br>Bangkapi, Huaikhwang, <br>Bangkok, 10310 <br><p class="m-0 mr-3 d-inline">Tel:</p><a href="tel:020263721" class="address cus-link d-inline">+66(0)2 026 3721</a></p>
                <a href="mailto:support@feels.co.th" class="address cus-link">support@feels.co.th</a>
            </div>
        </div>
    </div>
</main>