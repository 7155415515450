<main>
    <div class="banner d-flex justify-content-center align-items-center">
        <h1 class="text-white fs-50 line-h-100 text-center">
            ศูนย์รับเรื่องร้องเรียน
        </h1>
    </div>
    <div class="row justify-content-center m-0">
        <div class="col-12 col-lg-10 p-3 p-lg-5 mt-3 rounded-top-3 bg-white">
            <div class="mb-3 mt-lg-0 mt-3">
                <h1 class="fs-30 text-center text-light-green-main line-h-100">
                    ศูนย์รับเรื่องร้องเรียน
                </h1>
            </div>
            <div class="col-12 col-lg-10 mt-3 pl-0 pl-md-5 pr-0 pr-md-5 mx-auto">
                <p class="text-left m-0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    บริการรับเรื่องร้องเรียนจากลูกค้า ผ่านช่องทางต่างๆ เช่น โทรศัพท์, จดหมาย, E-mail โดยผู้ร้องเรียนต้องส่งสำเนาบัตรประจำตัวประชาชนทางไปรษณีย์ หรือโทรสาร หรือแนบเอกสารใน E-mail เพื่อประกอบการร้องเรียน ทั้งนี้ การร้องเรียนจะมีผลสมบูรณ์ก็ต่อเมื่อบริษัทฯ ได้รับเอกสารครบถ้วนแล้ว
                </p>
            </div>
            <div class="col-12 col-lg-6 mt-3 pl-0 pl-md-5 pr-0 pr-md-5 mx-auto">
                <p class="text-center m-0 mb-2">
                    <u><a download="คู่มือการรับเรื่องร้องเรียน" target="_blank" href="assets/pdf/คู่มือการรับเรื่องร้องเรียน.pdf" class="text-dark cus-link d-inline">คู่มือการรับเรื่องร้องเรียน</a></u>
                </p>
                <p class="text-center m-0 mb-2">
                    <u><a target="_blank" href="assets/pdf/สถิติเรื่องร้องเรียนและสรุปผลการรายงาน.pdf" class="text-dark cus-link d-inline">สถิติเรื่องร้องเรียนและสรุปผลการรายงาน</a></u>
                </p>
                <p class="text-center m-0 mb-2">
                    <u><a target="_blank" href="assets/pdf/นโยบายและหลักเกณฑ์การรับเรื่องร้องเรียน.pdf" class="text-dark cus-link d-inline">นโยบายและหลักเกณฑ์การรับเรื่องร้องเรียน</a></u>
                </p>
                <p class="text-center m-0 mb-2">
                    <i class="fas fa-phone-alt mr-2"></i> หมายเลขโทรศัพท์ : <a href="tel:0976916444" class="text-dark cus-link d-inline">+66(0)97 691 6444</a>
                </p>
                <p class="text-center m-0 mb-2">
                    <i class="fas fa-envelope mr-2"></i> E-mail : <a href="mailto:support@feels.co.th" class="text-dark cus-link d-inline">support@feels.co.th</a>
                </p>
                <p class="text-center m-0 mb-3">
                    <i class="fas fa-business-time mr-2"></i> วันจันทร์ - ศุกร์ 09.00-18.00 น.
                </p>
                <h1 class="fs-20 text-center text-danger line-h-100">
                    โดยไม่เสียค่าบริการ
                </h1>
            </div>
            <div class="col-12 col-lg-10 mt-3 pl-0 pl-md-5 pr-0 pr-md-5 mx-auto">
                <hr>
                <h3 class="text-center m-0">ศูนย์รับเรื่องร้องเรียน</h3>
                <h3 class="text-center m-0">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</h3>
                <h3 class="text-center m-0">21/111-112 ซอยศูนย์วิจัย ถนนพระราม 9 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310</h3>
            </div>
        </div>
    </div>
</main>